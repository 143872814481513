import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { Layout } from '@/layout/Layout';
import Seo from '../components/common/Seo';
import { WhereBuy } from '../components/modules/home';

import { products, filesData } from '../components/modules/products/data';

const ProductsPage = () => {
  return (
    <Layout pageId="products" isHeaderTransparent={true} headerWhiteText={true}>
      <section className="relative h-[280px] overflow-hidden bg-center md:h-[360px]">
        <StaticImage
          src="../assets/images/manuales-productos.png"
          alt=""
          className="absolute left-0 top-0 min-h-full w-full"
        />
        <div className="absolute left-0 top-0 z-10 flex w-full flex-col items-center pt-[120px] md:pt-[159px]">
          <h1 className="mb-8 w-full text-center text-[32px] font-bold leading-10 text-white md:text-6xl">
            Centro de soporte
          </h1>
          <a href="/" className="btn md:hidden">
            Escribir al whatsapp
          </a>
        </div>
      </section>

      <section className="pb-6 pt-8 md:py-12">
        <div className="container flex flex-col items-center gap-4 text-center md:gap-2">
          <span className="text-2xl font-bold md:text-[32px] md:leading-10">Selecciona el modelo de tu artículo</span>
          <span className="font-light text-[#697276]">
            para acceder a los manuales de usuario, firmwares u otras descargas
          </span>
        </div>
      </section>

      <DocumentList />

      <section className="bg-[#F5F5F5] py-12 md:py-10">
        <div className="container">
          <div className="row gap-8">
            <div className="mx-auto flex flex-col items-center text-center md:col-10">
              <p className="text-2xl text-[#303030]">
                Para más dúdas contacta <strong>nuestro servicio técnico:</strong>
              </p>
              <a
                href="tel:0-800-000 27"
                className="text-[32px] font-bold leading-10 text-[#00AAA6] md:text-[40px] md:leading-relaxed"
              >
                0-800-000 27
              </a>
            </div>
            <div className="mx-auto md:col-10">
              <div className="flex flex-col items-center justify-around gap-6 rounded-2xl bg-white px-8 py-6 md:flex-row">
                <p className="flex flex-col text-center md:text-left">
                  <span className="mb-2 text-2xl font-bold text-[#303030] md:mb-4">
                    Conoce todos los productos de Hisense Peru
                  </span>
                  <span className="font-light text-[#78909C]">Conoce los productos desde tv, electro hogar y más.</span>
                </p>
                <Link to="/" className="btn" state={{ scrollToProducts: true }}>
                  Ver productos
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <WhereBuy />
    </Layout>
  );
};

const DocumentList = () => {
  const [filters, setFilters] = useState({ productType: '', product: '' });

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const productTypeOptions = ['TV'];
  const productOptions = products.filter((i) => i.productType === filters.productType);

  const filteredFiles = filesData.filter((i) => i.productId === filters.product);

  return (
    <section className="bg-[#F5F5F5] pt-6 md:pt-10">
      <div className="container">
        <div className="row">
          {/* Filters */}
          <div className="flex flex-col justify-center gap-8 lg:col-12 lg:flex-row lg:gap-10 lg:pb-10">
            <label className="flex flex-col items-center gap-2 md:flex-row md:gap-4">
              <span className="shrink-0">Tipo de producto:</span>
              <select
                className="w-full rounded-full border-none lg:w-[225px]"
                name="productType"
                value={filters.productType}
                onChange={handleChange}
              >
                <option value="">Selecciona</option>
                {productTypeOptions.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </label>

            <label className="flex flex-col items-center gap-2 md:flex-row md:gap-4">
              <span className="shrink-0">Modelo:</span>
              <select
                className="w-full rounded-full border-none lg:w-[418px]"
                name="product"
                value={filters.product}
                onChange={handleChange}
              >
                <option value="">Selecciona</option>
                {productOptions.map((option) => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </select>
            </label>
          </div>

          {/* Cards */}
          <div className="mx-auto border-[#303030] border-opacity-10 py-6 lg:col-10 lg:border-y-2 lg:py-8">
            <div className="mb-4 md:mb-12">
              <p className="text-center text-[#697276] lg:text-left">Tienes {filteredFiles.length} Documentos</p>
            </div>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-8">
              {filteredFiles.map((item) => (
                <div key={item.id} className="rounded-2xl bg-white p-4">
                  <p className="font-bold text-[#303030]">{item.name}</p>
                  <p className="mb-6 text-[#697276]">Modelo: H20456</p>
                  <a href={item.file} download={item.name} className="font-bold text-[#00AAA6]">
                    Descargar
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Head = () => <Seo title="Centro de soporte | Hisense" />;

export default ProductsPage;
