import File1 from '@/assets/files/manuales-productos/A4H_A6H - 70533_E-Manual_9602_VIDAA_U5_SA_pdf_large_spa_20220325.pdf';
import File2 from '@/assets/files/manuales-productos/U6 - 71780_E-Manual_9612_GTV_SA__pdf_large_spa_20220419.pdf';
import File3 from '@/assets/files/manuales-productos/U7_U8 - 72953_E-Manual_9900_GTV_SA_pdf_large_spa_20220706.pdf';

export const products = [
  {
    id: "1",
    productType: 'TV',
    name: 'A4H_A6H'
  },
  {
    id: "2",
    productType: 'TV',
    name: 'U6'
  },
  {
    id: "3",
    productType: 'TV',
    name: 'U7_U8'
  },
];

export const filesData = [
  {
    id: "1",
    productId: "1",
    name: 'A4H_A6H - 70533',
    file: File1,
  },
  {
    id: "2",
    productId: "2",
    name: 'U6 - 71780',
    file: File2,
  },
  {
    id: "3",
    productId: "3",
    name: 'U7_U8 - 72953',
    file: File3,
  },
];
